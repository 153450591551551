import { useState } from "react";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import FilterProfileBlocks from "./FilterProfileBlocks";

export default function FilterProfileForm() {
	const [formData, setFormData] = useState({ ...window.data.filter_profiles_values });
	const creating = window.data.filter_profiles_values.id === -1;
	const filterTypes = window.data.filter_types;
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	const onSuccess = response => {
		if (response.data.id) {
			Nav.go(route("crm.cms.filter-profiles.edit", response.data.id));
		} else {
			Nav.go(route("crm.cms.filter-profiles.index"));
		}
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("cms.filter_profiles.singular")}</h1>

				<a className="link" href={route("crm.cms.filter-profiles.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "cms.filter_profiles.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating ? route("crm.cms.filter-profiles.store") : route("crm.cms.filter-profiles.update", formData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "cms.filter_profiles.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "cms.filter_profiles.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="cms.filter_profiles.fields.name"
										required
									/>
								</div>
							</div>

							<div className="form-full">
								<label htmlFor="positive">
									<Translate content="cms.filter_profiles.fields.positive_negative" />{" "}
									<span className="text-orange">*</span>
								</label>
								<RadioGroup
									name="positive"
									value={formData.positive}
									onChange={value => genericHandler(value, "positive")}>
									<Radio
										label={uctrans("cms.filter_profiles.fields.positive")}
										hint={uctrans("cms.filter_profiles.positive_description")}
										value
									/>
									<Radio
										label={uctrans("cms.filter_profiles.fields.negative")}
										hint={uctrans("cms.filter_profiles.negative_description")}
										value={false}
									/>
								</RadioGroup>
							</div>

							<div className="form-full">
								<label htmlFor="filter_type">
									<Translate content="cms.filter_profiles.fields.filter_type" />
								</label>
								<RadioGroup
									name="filter_type"
									value={formData.filter_type}
									onChange={value => genericHandler(value, "filter_type")}>
									{filterTypes.map((filterType, index) => (
										<Radio value={filterType.value} key={index} inline>
											<span>{filterType.label}</span>
											<span className="text-sm italic ml-4">
												{formData.positive ? filterType.description_positive : filterType.description_negative}
											</span>
										</Radio>
									))}
								</RadioGroup>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="cms.filter_profiles.singular" addAnotherAvailable={false} />
								</div>
							</div>
							{formData.active_pages &&
								Object.keys(formData.active_pages) &&
								Object.keys(formData.active_pages).length > 0 && (
									<div className="form-full">
										<label htmlFor="filter_type">
											<Translate content="cms.filter_profiles.fields.active_count" />
										</label>
										<div>
											{Object.entries(formData.active_pages).map(([key, connectedPage]) => (
												<p className="link" key={key}>
													<a href={route("crm.cms.pages.edit", connectedPage.id)}>
														{`${connectedPage.title} ${connectedPage.count > 1 ? `(${connectedPage.count}x)` : ""}`}
													</a>
												</p>
											))}
										</div>
									</div>
								)}
						</div>
					</div>
				</div>
			</AjaxForm>
			{formData.id !== -1 && <FilterProfileBlocks filterProfile={formData} />}
		</>
	);
}
